<script lang="ts" setup>
import { computed } from 'vue'
import type { SelectorItem } from '@/composables/selector'
import BaseIcon from './BaseIcon.vue'
import DButton from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const props = defineProps<{
    modelValue: string
    options: SelectorItem[]
}>()

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value?: string): void
    (name: 'unselect'): void
}>()

const selected = computed(() => props.options.filter((item) => item.id === props.modelValue))
const onModelUpdate = (item?: SelectorItem[]) => {
    if (!item) return
    emit('update:modelValue', item?.[0].id)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="options"
        max-width="sm"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelUpdate"
    >
        <DButton data-testid="d-selector-data-product-type">
            <BaseIcon v-if="selected[0]?.icon" :icon="selected[0]?.icon" class="text-slate-500" size="md" />
            {{ selected[0]?.label }}
        </DButton>
    </DSelectorGeneric>
</template>
