<script lang="ts" setup>
import { ref, computed, useTemplateRef } from 'vue'
import type { Person } from '@prisma/client'

import { type SelectorItem } from '@/composables/selector'

import BaseIcon from './BaseIcon.vue'
import DAvatar from './DAvatar.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DTooltip from './DTooltip.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const props = defineProps<{
    modelValue?: string[]
    people?: (Pick<Person, 'id' | 'name' | 'photoUrl'> & { disabled?: boolean; disabledText?: string })[]
    placeholder?: string
    filterPlaceholder?: string
    selectedLabel?: string
    buttonType?: DButtonType
    showTooltip?: boolean
}>()

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const singlePersonName = useTemplateRef('singlePersonName')
const peopleTooltip = useTemplateRef('peopleTooltip')
const isSelectorOpened = ref(false)

const options = computed(() => {
    const mappedItems =
        props.people?.map((person) => ({
            id: person.id,
            label: person.name,
            avatar: {
                id: person.id,
                photoUrl: person.photoUrl,
            },
            disabled: person.disabled,
            disabledText: person.disabledText,
        })) ?? []
    return mappedItems as SelectorItem[]
})
const selectedPeople = computed(() => options.value.filter(({ id }) => props.modelValue?.includes(id)))
const selectedPeopleAvatars = computed(() => {
    return selectedPeople.value.map((person) => ({
        id: person.avatar?.id,
        photoUrl: person.avatar?.photoUrl,
    }))
})

function onModelValueUpdate(items: SelectorItem[]) {
    const modelValue = items.map((item) => item.id)
    emit('update:modelValue', modelValue)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selectedPeople"
        :options="options"
        multiple
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        :no-items-label="$t('components.SelectorItem.noPersonsFound')"
        @show="isSelectorOpened = true"
        @hide="isSelectorOpened = false"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect', $event)"
        @update:model-value="onModelValueUpdate"
    >
        <slot>
            <DButton
                id="peopleSelector"
                :type="buttonType ?? 'ghost'"
                @mouseenter="peopleTooltip?.show"
                @mouseleave="peopleTooltip?.hide"
            >
                <template v-if="selectedPeople?.length">
                    <div class="flex gap-x-1 overflow-hidden">
                        <DAvatar v-if="selectedPeople.length" :users="selectedPeopleAvatars" size="sm" :max="3" />
                        <span v-if="selectedPeople.length === 1" ref="singlePersonName" class="truncate">
                            {{ selectedPeople[0]?.label }}
                        </span>
                        <span v-else-if="selectedPeople.length > 1" class="truncate capitalize">
                            {{ selectedPeople.length }} {{ selectedLabel }}
                        </span>
                    </div>
                </template>
                <template v-else>
                    <BaseIcon icon="users-group" class="text-slate-500" size="md" />
                    <span class="truncate">{{ placeholder }}</span>
                </template>
            </DButton>

            <DTooltip v-if="showTooltip" ref="peopleTooltip" placement="bottom-end" anchor="peopleSelector">
                <div class="grid grid-cols-1 gap-2 p-1">
                    <div v-for="person in selectedPeople" :key="person?.id" class="flex items-center gap-x-2">
                        <DAvatar :users="[{ id: person?.id, photoUrl: person?.avatar?.photoUrl }]" size="xs" />
                        <span class="truncate">{{ person?.label }}</span>
                    </div>
                </div>
            </DTooltip>
        </slot>
    </DSelectorGeneric>
</template>
