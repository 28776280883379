<script lang="ts" setup>
import { computed } from 'vue'

import type { SelectorItem } from '@/composables/selector'

import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const props = withDefaults(
    defineProps<{
        modelValue?: string[]
        keywords: { id: string; label: string }[]
        placeholder?: string
        filterPlaceholder?: string
        buttonType?: DButtonType
        testid?: string
    }>(),
    {
        testid: 'd-selector-keywords',
        buttonType: 'ghost',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value?: string): void
    (name: 'unselect'): void
}>()

const selected = computed(() => props.keywords.filter((item) => props.modelValue?.includes(item.id)))
const showPlaceholder = computed(() => props.placeholder && !props.modelValue?.length)

const onModelUpdate = (items: SelectorItem[]) => {
    if (!items) return
    emit(
        'update:modelValue',
        items.map((item) => item.id)
    )
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="keywords"
        max-width="sm"
        multiple
        :filter-placeholder="filterPlaceholder"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelUpdate"
    >
        <DButton :type="buttonType ?? 'ghost'" :data-testid="testid">
            <template v-if="showPlaceholder">
                <BaseIcon icon="keyword" class="text-slate-500" size="md" />
                {{ placeholder }}
            </template>
            <template v-else>
                <BaseIcon icon="keyword" size="md" />
                <span class="truncate">
                    {{
                        selected.length > 1
                            ? $t('filters.properties.keyword', { count: selected.length })
                            : selected[0]?.label
                    }}
                </span>
            </template>
        </DButton>
    </DSelectorGeneric>
</template>
