import type { ServerOptions } from './types'
export const sharedConfig: Pick<ServerOptions, 'trpcPrefix'> = {
    trpcPrefix: '/trpc',
}

export const MAX_LENGTH = {
    /** 255 characters */
    TITLE: 255,
    /** 100 characters */
    SHORT_TITLE: 100,
    /** 2000 characters */
    DESCRIPTION: 2000,
    /** 5000 characters */
    COMMENT: 5000,
    /** 300 characters */
    NAME: 300,
    /** 50 characters */
    MEDIUM_LABEL: 50,
    /** 30 characters */
    SHORT_LABEL: 30,
    /** 20 characters */
    ATTRIBUTE: 20,
    /** 100 characters */
    SCALE: 100,
    /** 2048 characters */
    URL: 2048,

    ENTITY_TYPE_LABEL_SINGULAR: 20,
    ENTITY_TYPE_LABEL_PLURAL: 25,
    ENTITY_TYPE_ABBREVIATION: 2,
}

// https://stackoverflow.com/a/57225494
export const MAX_SAFE_NUMBER = {
    DECIMAL_SCALE_2: 70368744177663.99,
    INTEGER_IN_DECIMAL_SCALE_2: 70368744177663,
}

export const OwnerFeatureToggle = {
    copilot: 'copilot',
    demo: 'demo',
    savedViews: 'savedViews',
} as const

export const AdminFeatureToggle = {
    segments: 'segments',
} as const

export const FeatureToggle = {
    ...OwnerFeatureToggle,
    ...AdminFeatureToggle,
} as const

export type FeatureToggle = (typeof FeatureToggle)[keyof typeof FeatureToggle]
export type OwnerFeatureToggle = (typeof OwnerFeatureToggle)[keyof typeof OwnerFeatureToggle]
export type AdminFeatureToggle = (typeof AdminFeatureToggle)[keyof typeof AdminFeatureToggle]

export type FeatureToggleOptions = {
    visibleOnOrganizationsManagement: boolean
    isOrganizationControlled: boolean
    defaults: {
        isEnabled: boolean
    }
}

export const featureToggles = {
    copilot: {
        visibleOnOrganizationsManagement: true,
        isOrganizationControlled: false,
        defaults: {
            isEnabled: false,
        },
    },
    demo: {
        visibleOnOrganizationsManagement: true,
        isOrganizationControlled: false,
        defaults: {
            isEnabled: false,
        },
    },
    segments: {
        visibleOnOrganizationsManagement: false,
        isOrganizationControlled: true,
        defaults: {
            isEnabled: false,
        },
    },
    savedViews: {
        visibleOnOrganizationsManagement: true,
        isOrganizationControlled: false,
        defaults: {
            isEnabled: false,
        },
    },
} as const satisfies Record<FeatureToggle, FeatureToggleOptions>

export const MAX_NUMBER_OF_DATA_PRODUCT_TYPES = 5
export const MIN_NUMBER_OF_DATA_PRODUCT_TYPES = 1
