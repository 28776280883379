import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import { computed } from 'vue'
import { EntityCoreTypeEnum } from '@prisma/client'
import { useI18n } from 'vue-i18n'
import type { RouterOutput } from '@/types'

export type EntityCoreTypeOrTypeId = EntityCoreTypeEnum | string

type EntityTypeType = RouterOutput['entityType']['getAll'][number]
type EntityTypeMapType = Record<string, EntityTypeType>

export const isEntityCoreType = (type: EntityCoreTypeOrTypeId): type is EntityCoreTypeEnum => {
    return type in EntityCoreTypeEnum
}

export const useEntityTypes = () => {
    const { t } = useI18n()
    const { data: entityTypes, isFetched } = useQuery({
        queryKey: ['entityTypesList'],
        queryFn: () => client.entityType.getAll.query(),
        initialData: [],
        queries: ['EntityType'],
    })

    const entityTypesMapById = computed<EntityTypeMapType>(() => {
        if (isFetched.value && entityTypes.value.length === 0) {
            throw new Error('Something went wrong. No entity types found.')
        }

        return entityTypes.value.reduce((acc, type) => {
            acc[type.id] = type
            return acc
        }, {} as EntityTypeMapType)
    })

    const dataProductType = computed<Omit<EntityTypeType, 'id' | 'oldEntityType'>>(() => ({
        type: EntityCoreTypeEnum.DataProduct,
        labelSingular: t('entityTypes.DataProduct', 1),
        labelPlural: t('entityTypes.DataProduct', 10),
        abbreviation: 'DP',
        icon: 'data-product-type',
        borderColor: 'border-cyan-200',
        routePath: 'data-product',
    }))

    const dataProductTypes = computed(() => {
        const types = entityTypes.value.filter(({ type }) => type === EntityCoreTypeEnum.DataProduct)
        if (isFetched.value && types.length === 0) {
            throw new Error('Something went wrong. No data product types found.')
        }
        return types
    })

    const opportunityType = computed<EntityTypeType>(() => {
        const type = entityTypes.value.find(({ type }) => type === EntityCoreTypeEnum.Opportunity)

        if (isFetched.value && !type) {
            throw new Error('Something went wrong. No opportunity entity type found.')
        }

        if (!type) {
            // Note: Not yet sure is this a good idea but otherwise we need to handle missing opportunityType
            return {
                id: '',
                type: EntityCoreTypeEnum.Opportunity,
                labelSingular: '',
                labelPlural: '',
                abbreviation: '',
                icon: '',
                borderColor: '',
                routePath: '',
            } satisfies EntityTypeType
        }

        return type
    })

    return {
        entityTypes,
        entityTypesMapById,
        dataProductType,
        dataProductTypes,
        opportunityType,
    }
}
