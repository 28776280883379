<script setup lang="ts">
import { ref, computed, useSlots, nextTick, useTemplateRef, type ComponentPublicInstance } from 'vue'

import difference from 'lodash/difference'
import type { PassThrough } from 'primevue/ts-helpers'
import type { ListboxPassThroughOptions, ListboxChangeEvent } from 'primevue/listbox'
import Listbox from 'primevue/listbox'
import OverlayPanel from 'primevue/overlaypanel'

import { useSelector, type SelectorItem } from '@/composables/selector'
import type { Size } from '@/composables/sizing'

import BaseIcon from '@/components/BaseIcon.vue'
import DButton from '@/components/DButton.vue'
import DCheckbox from '@/components/DCheckbox.vue'
import DAvatar from '@/components/DAvatar.vue'

const props = withDefaults(
    defineProps<{
        modelValue?: SelectorItem[]
        options: SelectorItem[]
        unselectItemLabel?: string
        unselectItemIcon?: string
        multiple?: boolean
        filter?: boolean
        filterIcon?: string | null
        filterPlaceholder?: string
        filterAutofocus?: boolean
        closeOnSelection?: boolean
        topOffset?: boolean
        maxWidth?: Size
        maxHeight?: boolean
        group?: boolean
        zIndexClass?: string
        noItemsLabel?: string
        testid?: string
        disabled?: boolean
        icon?: string
    }>(),
    {
        multiple: false,
        filter: true,
        filterIcon: 'search',
        filterAutofocus: true,
        closeOnSelection: true,
        topOffset: true,
        maxWidth: 'md',
        maxHeight: true,
        zIndexClass: 'z-selector',
        testid: 'd-selector-generic',
        icon: 'plus',
    }
)

defineOptions({
    inheritAttrs: false,
})

const emit = defineEmits<{
    (name: 'update:model-value', value: SelectorItem[]): void
    (name: 'select-item', value: string): void
    (name: 'unselect-item', value: string): void
    (name: 'show'): void
    (name: 'hide'): void
}>()

const { UNSELECT_ITEM } = useSelector()
const slots = useSlots()

const overlay = useTemplateRef('overlay')
// filterValue is not exposed by Primevue- it's used internally to clear the filter input
const listbox = useTemplateRef<typeof Listbox & ComponentPublicInstance & { filterValue: string | null }>('listbox')
const labelRefMap = ref(new Map())
const isOpened = ref(false)

defineExpose({
    alignOverlay() {
        // flickers when it's at the very right of the screen
        nextTick(() => {
            overlay.value?.alignOverlay()
        })
    },
    hide() {
        overlay.value?.hide()
    },

    filterInputClear() {
        if (!listbox.value) return
        listbox.value.filterValue = null
    },
    filterInputFocus() {
        if (!listbox.value) return
        listbox.value.$el.querySelector('[data-pc-section="filterinput"]').focus()
    },
})

const overlayPanelPT = computed(() => ({
    root: {
        class: [props.zIndexClass, 'absolute left-0 top-0 transform origin-center'],
    },
    content: {
        class: 'items-center flex',
    },
}))
const listboxPT = computed<PassThrough<ListboxPassThroughOptions>>(() => ({
    root: {
        class: [
            'w-full flex flex-col bg-white rounded-md transition-all duration-300 linear shadow-elevation-medium',
            {
                'max-h-80': props.maxHeight,
                'max-h-dvh': !props.maxHeight,
                'max-w-xs': props.maxWidth === 'sm',
                'max-w-sm': props.maxWidth === 'md',
                'max-w-md': props.maxWidth === 'lg',
                'pt-0.5': props.topOffset,
            },
        ],
    },
    wrapper: {
        class: 'overflow-auto',
    },
    list: {
        class: ['list-none m-0', { 'py-2': !slots?.item }],
    },
    itemGroup: {
        class: 'text-xs pt-1.5 px-2.5 mt-2 first:mt-0 text-slate-400 border-t border-slate-300 first:border-none',
    },
    item: {
        class: { 'px-1 mt-1.5': !slots?.item },
    },
    header: {
        class: 'rounded-tl-lg rounded-tr-lg border-b border-slate-300 py-2',
    },
    filterContainer: {
        class: 'flex items-center gap-x-1 px-4 py-1',
    },
    filterInput: {
        class: [
            'w-full font-sans text-base text-slate-400 bg-white',
            'focus:outline-none transition duration-200 appearance-none',
            'placeholder:text-slate-400 placeholder:text-base',
        ],
    },
    filterIcon: {
        class: 'hidden',
    },
    emptyMessage: {
        class: 'flex items-center justify-center text-slate-400',
    },
}))
const mappedOptions = computed(() => {
    const options = props.options || []
    const unselectItem = props.unselectItemLabel
        ? {
              id: UNSELECT_ITEM.id,
              label: props.unselectItemLabel || UNSELECT_ITEM.label,
              icon: props.unselectItemIcon || UNSELECT_ITEM.icon,
          }
        : null

    if (!unselectItem || props.multiple) return options
    if (!options.length && unselectItem) return [unselectItem]

    return [unselectItem, ...options]
})

async function toggleOverlay(event: Event) {
    if (props.disabled) return
    await overlay.value?.toggle(event)
    if (isOpened.value) overlay.value?.alignOverlay()
}

function isItemSelected(id: string) {
    if (!props.modelValue?.length && id === UNSELECT_ITEM.id) return true
    return props.modelValue?.some((value) => value.id === id)
}

function update(selected: SelectorItem | SelectorItem[]) {
    if (Array.isArray(selected)) {
        emit('update:model-value', selected)
    } else {
        emit('update:model-value', [selected])
    }
    if (!props.multiple && props.closeOnSelection) overlay.value?.hide()
}

function onChange(e: ListboxChangeEvent) {
    const selectedItems = props.modelValue || []

    //multiselect
    if (Array.isArray(e.value)) {
        if (selectedItems && selectedItems.length < e.value.length) {
            const selectedItem = difference(e.value, selectedItems)
            emit('select-item', selectedItem[0].id)
        } else {
            const unselectedItem = difference(selectedItems, e.value)
            emit('unselect-item', unselectedItem[0].id)
        }
        return
    }

    //single select
    if (e.value.id === selectedItems[0]?.id) return // do nothing if the same item is selected

    if (e.value.id === UNSELECT_ITEM.id) {
        if (!selectedItems?.length) return //emit unselect only if there is a selected item

        emit('unselect-item', selectedItems[0].id)
        return
    }

    emit('select-item', e.value.id)
}

function onHide() {
    isOpened.value = false
    emit('hide')
}

function onShow() {
    isOpened.value = true
    emit('show')
}
</script>

<template>
    <div :data-testid="testid" @click="toggleOverlay" @keydown.enter="toggleOverlay">
        <slot>
            <DButton type="ghost" :icon="icon" :disabled="disabled" />
        </slot>

        <OverlayPanel
            id="overlay-d-selector-generic"
            ref="overlay"
            :data-testid="`${testid}-overlay`"
            unstyled
            :auto-z-index="false"
            :pt="overlayPanelPT"
            @show="onShow"
            @hide="onHide"
        >
            <Listbox
                id="d-selector-generic-menu"
                ref="listbox"
                :data-testid="`${testid}-listbox`"
                :model-value="modelValue"
                :options="mappedOptions"
                option-label="label"
                option-disabled="disabled"
                :option-group-label="group ? 'label' : undefined"
                :option-group-children="group ? 'children' : undefined"
                :filter="filter"
                :filter-placeholder="filterPlaceholder || $t('search.label')"
                :filter-input-props="{ autofocus: filterAutofocus }"
                unstyled
                :pt="listboxPT"
                :multiple="multiple"
                :meta-key-selection="false"
                @change="onChange"
                @update:model-value="update"
            >
                <template #header>
                    <div v-if="$slots.header" class="border-b border-slate-300 py-2 px-3">
                        <slot name="header" />
                    </div>
                </template>
                <template #filtericon>
                    <span v-if="filterIcon === null" />
                    <BaseIcon v-else :icon="filterIcon" size="md" class="text-slate-400" />
                </template>
                <template #emptyfilter>
                    <div class="flex items-center justify-center">
                        <span class="text-base text-slate-400">{{
                            noItemsLabel || $t('components.SelectorItem.noItemsFound')
                        }}</span>
                    </div>
                </template>
                <template #option="{ option }">
                    <slot name="item" :item="option">
                        <div
                            :data-testid="`${testid}-option-${option.id}`"
                            class="relative flex items-center gap-x-1.5 overflow-hidden whitespace-nowrap rounded px-2 py-1 font-normal"
                            :class="[
                                option.hoverColor ? `${option.hoverColor}` : 'text-slate-700 hover:bg-slate-100',
                                option.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                            ]"
                        >
                            <DCheckbox
                                v-if="multiple"
                                :model-value="isItemSelected(option.id)"
                                size="md"
                                :disabled="option.disabled"
                            />
                            <div class="flex w-full items-center justify-between gap-x-2 truncate">
                                <div class="flex items-center gap-x-1 truncate">
                                    <BaseIcon
                                        v-if="option.icon"
                                        :icon="option.icon"
                                        size="md"
                                        :class="option.iconColor ?? 'text-slate-500'"
                                    />
                                    <DAvatar
                                        v-if="option.avatar"
                                        :users="[option.avatar]"
                                        :selected-user-id="option.avatar.id"
                                        size="xs"
                                    />
                                    <span
                                        :ref="(el) => labelRefMap.set(option.id, el)"
                                        v-tooltip.top="
                                            labelRefMap.get(option.id) &&
                                            labelRefMap.get(option.id).offsetWidth <
                                                labelRefMap.get(option.id).scrollWidth
                                                ? option.label
                                                : null
                                        "
                                        class="truncate text-sm"
                                        :class="[
                                            (!option.disabled && option.labelColor) ?? option.labelColor,
                                            {
                                                'text-slate-400': option.disabled,
                                            },
                                        ]"
                                    >
                                        {{ option.label }}
                                    </span>
                                </div>
                                <span v-if="option.disabled && option.disabledText" class="text-sm text-slate-400">
                                    {{ option.disabledText }}
                                </span>
                                <BaseIcon
                                    v-if="!multiple && isItemSelected(option.id)"
                                    icon="check"
                                    size="md"
                                    class="text-slate-500"
                                />
                            </div>
                        </div>
                    </slot>
                </template>
            </Listbox>
        </OverlayPanel>
    </div>
</template>
