import { differenceInMinutes, differenceInHours, differenceInDays, format, subMinutes } from 'date-fns'
import { useI18n } from 'vue-i18n'

export const useFormatTimeAgo = (date: Date | undefined) => {
    const { t } = useI18n()
    if (!date) return

    const currentDate = new Date()
    const formatResult = (value: number, timeType: 'minute' | 'hour' | 'day') => {
        const isPlural = value > 1
        const text = isPlural ? timeType + 's' : timeType
        return `${value} ${text} ${t('ago')}`
    }

    const daysDiff = Math.abs(differenceInDays(date, currentDate))
    if (daysDiff) {
        return formatResult(daysDiff, 'day')
    }

    const hoursDiff = Math.abs(differenceInHours(date, currentDate))
    if (hoursDiff) {
        return formatResult(hoursDiff, 'hour')
    }

    const minutesDiff = Math.abs(differenceInMinutes(date, currentDate))
    if (minutesDiff) {
        return formatResult(minutesDiff, 'minute')
    }

    return t('justNow')
}

/**
 * Formats the date like in the design:  '13 April 2024'
 */
export const formatDateLocale = (date: Date) => {
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    })
}

export const formatUTCDate = (date: Date) => {
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC',
    })
}

export const formatDateTime = (date: Date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss')
}

export const formatDateAsYYYYMMDD = (date: Date) => {
    return format(date, 'yyyyMMdd')
}

/*
Date picker selects the date in the local timezone,
then the superjson converts it to UTC date string,
but we need to store ONLy the date (no time) for all possible timezones.
This is why we are offsetting the date by the timezone offset to match the date in the UTC timezone.
Example:
date picker selected: Fri Sep 20 2024 00:00:00 GMT+0200
superjson converts to: 2024-09-19T22:00:00.000Z
data stored in the database: 2024-09-19T00:00:00.000Z

We skip the time and its invalid. Even if we would store the time, it would be invalid because of the timezone offset.
What happened is that in every timezone you end up with different dates offset by the timezone offset.
With this function basically every date picker date is stored as 00:00:00 in the UTC timezone.
*/
export const convertToUTCDate = (date?: Date | null) => {
    return date ? subMinutes(date, date.getTimezoneOffset()) : null
}
