<script setup lang="ts">
import { computed, useTemplateRef, type ComponentPublicInstance } from 'vue'
import DLabel from './DLabel.vue'
import type { Size } from '@/composables/sizing'

interface CalendarPassThroughMethodOptions {
    context: {
        selected: boolean
    }
}

const props = withDefaults(
    defineProps<{
        id: string
        modelValue?: null | Date
        label?: string
        placeholder?: string
        error?: string | boolean
        size?: Size
        disabled?: boolean
        focusOnMounted?: boolean
        blurOnEnter?: boolean
        ghost?: boolean
        mediumText?: boolean
        cursorPointer?: boolean
        lightTextColor?: boolean
    }>(),
    {
        disabled: false,
        size: 'md',
    }
)
const emit = defineEmits(['update:modelValue', 'date-select'])

defineExpose({
    focus() {
        focusInput()
    },
    blur() {
        blurInput()
    },
})

const datePicker = useTemplateRef<ComponentPublicInstance>('datePicker')

const DDatePickerInputClasses = computed(() => [
    'border rounded  shadow-none focus:shadow-none py-0',
    {
        'p-0': props.ghost,
        'px-1': !props.ghost && props.size === 'sm',
        'px-2': !props.ghost && props.size === 'md',
        'px-3': !props.ghost && props.size === 'lg',
    },
    {
        'bg-transparent': !props.disabled,
        'text-slate-700': !props.disabled,
    },
    {
        'text-sm leading-6 h-6': props.size === 'sm',
        'text-base leading-7': props.size === 'md',
        'text-base leading-8': props.size === 'lg',
    },
    {
        'font-medium': props.mediumText,
    },
    {
        'placeholder-slate-400': props.placeholder,
    },
    {
        'border-none': props.ghost,
        'bg-rose-50': props.ghost && props.error,
        'border-rose-500': !props.ghost && props.error,
        'border-slate-300': !props.ghost && !props.error,
        '!text-slate-500': props.lightTextColor,
    },
    {
        'border-slate-300 text-slate-400 bg-slate-100': props.disabled && !props.ghost,
    },
    {
        'focus:outline-none border focus:border-primarySky-300': !props.disabled && !props.ghost,
    },
])

const DDatePickerDayLabelClasses = ({ context }: CalendarPassThroughMethodOptions) => [
    'p-0 w-5 h-6 rounded-md text-sm hover:bg-slate-300 text-slate-500 focus:outline-none focus:shadow-none',
    {
        'opacity-60 cursor-default': props.disabled,
        'cursor-pointer': !props.disabled,
    },
    {
        'text-slate-600 bg-transparent hover:bg-slate-300': !context.selected && !props.disabled,
        'text-slate-700 bg-slate-200 hover:bg-slate-300': context.selected && !props.disabled,
    },
]

function focusInput() {
    if (!datePicker?.value) return
    datePicker.value.$el.querySelector('input')?.focus()
}
function blurInput() {
    if (!datePicker?.value) return
    datePicker.value.$el.querySelector('input')?.blur()
}
</script>

<template>
    <div class="flex flex-col">
        <DLabel v-if="label" :for-id="id" :label="label" padding-bottom />
        <PCalendar
            ref="datePicker"
            :input-id="id"
            :model-value="modelValue"
            date-format="dd.mm.yy"
            :placeholder="placeholder"
            :disabled="disabled"
            :pt="{
                root: { class: { 'h-6 leading-6': size === 'sm' } },
                input: { class: DDatePickerInputClasses },
                header: { class: 'p-0' },
                monthTitle: { class: 'text-slate-700' },
                yearTitle: { class: 'text-slate-700' },
                weekDay: { class: 'text-slate-700' },
                tableBodyRow: { class: 'p-0' },
                day: { class: 'p-0' },
                dayLabel: DDatePickerDayLabelClasses,
            }"
            @update:model-value="emit('update:modelValue', $event)"
            @date-select="$emit('date-select')"
        />
        <div v-if="typeof error === 'string'" class="text-xs font-normal text-rose-500">{{ error }}</div>
    </div>
</template>
