import { computed, type ComputedRef } from 'vue'
import { EntityCoreTypeEnum } from '@prisma/client'
import type { SelectorItem } from '../selector'
import { useCustomProperties } from './customProperties'
import { useEntityTypes } from '../entityType'

export const useDataProductTypesForCustomProperty = (customPropertyId?: ComputedRef<string | undefined>) => {
    const { dataProductTypes } = useEntityTypes()
    const { customProperties } = useCustomProperties(EntityCoreTypeEnum.DataProduct)

    const dataProductTypeIdsEnabledForCustomProperty = computed(() => {
        const customProperty = customProperties.value.find((attr) => attr.id === customPropertyId?.value)
        if (!customProperty) return []
        return customProperty.enabledFor
            .filter(({ entityType }) => entityType.type === EntityCoreTypeEnum.DataProduct)
            .map(({ entityType }) => entityType.id)
    })

    const dataProductTypeOptions = computed<SelectorItem[]>(() => {
        return dataProductTypes.value.map((type) => ({
            id: type.id,
            label: type.labelSingular,
            icon: type.icon,
            disabled: customPropertyId?.value
                ? !dataProductTypeIdsEnabledForCustomProperty.value.includes(type.id)
                : false,
        }))
    })

    return { dataProductTypeOptions, dataProductTypeIdsEnabledForCustomProperty }
}
