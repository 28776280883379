import { defineStore } from 'pinia'
import { type RedirectLoginOptions, type AppState } from '@auth0/auth0-vue'
import auth0 from '@/plugins/auth0'

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            isLoading: auth0.isLoading,
            isAuthenticated: auth0.isAuthenticated,
            user: auth0.user,
            accessToken: '',
        }
    },
    getters: {
        userRoles(): ('owner' | 'admin')[] {
            const AUTH0_NAMESPACE = window.env.AUTH0_NAMESPACE
            const rolesKey = `${AUTH0_NAMESPACE}/roles`
            const userRoles = this.user?.[rolesKey]
            return userRoles || []
        },

        isAdmin(): boolean {
            return this.userRoles.includes('admin') || false
        },

        isOwner(): boolean {
            return this.userRoles.includes('owner') || false
        },

        isAuth0User(): boolean {
            return this.mappedUser.connectionStrategyUsed === 'auth0'
        },

        mappedUser(): {
            auth0Id?: string
            name?: string
            organization?: {
                displayName?: string
            }
            email?: string
            photoUrl?: string
            onboardingCompleted?: boolean
            connectionStrategyUsed?: string
            authProviders?: string[]
        } {
            const AUTH0_NAMESPACE = window.env.AUTH0_NAMESPACE
            const orgDisplayNameKey = `${AUTH0_NAMESPACE}/org_display_name`
            const onboardingCompletedKey = `${AUTH0_NAMESPACE}/onboarding_completed`
            const connectionStrategyUsedKey = `${AUTH0_NAMESPACE}/connection_strategy_used`
            const authProvidersKey = `${AUTH0_NAMESPACE}/auth_providers`
            if (!this.user) return {}
            return {
                auth0Id: this.user.sub,
                name: this.user.name,
                photoUrl: this.user.picture,
                email: this.user.email,
                organization: { displayName: this.user[orgDisplayNameKey] },
                onboardingCompleted: this.user[onboardingCompletedKey],
                connectionStrategyUsed: this.user[connectionStrategyUsedKey],
                authProviders: this.user[authProvidersKey],
            }
        },
    },

    actions: {
        async getToken() {
            try {
                const token = await auth0.getAccessTokenSilently()
                return token
            } catch (e) {
                await auth0.loginWithRedirect({
                    appState: {
                        target: location.pathname,
                    },
                })
                throw new Error('Unable to get token')
            }
        },
        async login(options?: RedirectLoginOptions<AppState> | undefined) {
            await auth0.loginWithRedirect(options)
        },
        async logout() {
            await auth0.logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            })
        },
    },
})
