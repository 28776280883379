type InsertComplexOp = Record<string, unknown>

export type Mention = {
    denotationChar: string
    value: string
    id: string
    entityId: string
    type: MentionType
    disabled: boolean
    entityType?: string
}
export type MentionType = 'person' | 'entity' | 'empty'

interface InsertWithMention extends InsertComplexOp {
    mention: Mention
}

type InsertOp = string | InsertComplexOp | InsertWithMention

interface Op {
    // only one property out of {insert, delete, retain} will be present
    insert?: InsertOp
    delete?: number
    retain?: number | Record<string, unknown>

    attributes?: { [key: string]: unknown }
}

export interface QuillDeltaType {
    ops: Op[]
}

export const whiteSpaceRegex = /^\s+$/

export function isNotWhiteSpace(delta: QuillDeltaType) {
    return delta.ops.some((op) => {
        if (!op.insert) return false
        return typeof op.insert === 'string' ? !whiteSpaceRegex.test(op.insert.toString()) : true
    })
}

function isInsertOp(insert?: InsertOp): insert is InsertOp {
    return insert !== undefined
}

function isInsertWithMention(insert?: InsertOp): insert is InsertWithMention {
    return !!insert && typeof insert === 'object' && 'mention' in insert
}

// TS does not really get that filter removes undefined values - so we use this guard that does the same thing and also transforms type
function isMention(mention?: Mention): mention is Mention {
    return !!mention
}

export function getTextFromDelta(delta: QuillDeltaType): string {
    return delta.ops
        .map(({ insert }) => {
            if (!isInsertOp(insert)) return
            else if (isInsertWithMention(insert)) return insert.mention.denotationChar + insert.mention.value
            else return insert
        })
        .filter(Boolean)
        .join('')
}

export function getMentions(delta: QuillDeltaType): Mention[] {
    return delta.ops
        .map((op) => {
            if (!isInsertOp(op.insert)) return
            else if (!isInsertWithMention(op.insert)) return
            else return op.insert.mention
        })
        .filter(isMention)
}
