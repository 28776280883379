<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCustomToast } from '@/composables/toast'
import { marked } from 'marked'
import { EntityCoreTypeEnum } from '@prisma/client'

import { useLumoQueries } from '@/queries/lumo'
import { useFeatureToggles } from '@/composables/featureToggles'

import DTextEditor from '@/components/DTextEditor.vue'
import LumoButton from './lumo/LumoButton.vue'
import LumoInlineMessage from './lumo/LumoInlineMessage.vue'
import LumoActionMenu from './lumo/LumoActionMenu.vue'

const { t } = useI18n()
const toast = useCustomToast()

const { expandOpportunityDescription, expandDataProductDescription } = useLumoQueries()

const props = defineProps<{
    label: string
    editor?: InstanceType<typeof DTextEditor>
    type: EntityCoreTypeEnum
}>()
const emit = defineEmits<{
    (name: 'trigger-label-error'): void
}>()

const isEnabled = useFeatureToggles().copilot
const copilotBusy = ref(false)

const hasDescription = computed(() => !!props.editor?.textContentLength)
const lumoButtonDisabled = computed(() => !props.label && !hasDescription.value)
const lumoLabel = computed(() =>
    copilotBusy.value ? t('lumo.generating') : t(`lumo.${hasDescription.value ? 'regenerate' : 'generate'}`)
)

function validateInputs({ shouldReplace }: { shouldReplace: boolean }) {
    if (!props.editor) return
    if (!props.label && !hasDescription.value) {
        emit('trigger-label-error')
        return
    }

    generateDescription({ shouldReplace })
}

function generateDescription({ shouldReplace }: { shouldReplace: boolean }) {
    if (!props.editor) return

    let currentText = props.editor.getText()

    if (shouldReplace) {
        clearEditor()
        currentText = ''
    }
    copilotBusy.value = true
    props.editor.expandContent()

    if (props.editor.textContentLength > 0) {
        props.editor.insertLineBreak()
        props.editor.save()
    }
    const lastIndex = props.editor.getLastIndex() ?? 0

    let firstChunk = true
    let fullText = ''

    if (props.type === EntityCoreTypeEnum.Opportunity) {
        expandOpportunityDescription(props.label, currentText, onData, onError, onComplete)
    } else {
        expandDataProductDescription(props.label, currentText, onData, onError, onComplete)
    }

    function onData(chunk: string) {
        if (!chunk || !props.editor) return
        // Remove leading whitespace from first chunk
        // TODO: Check if this is still needed after we switch LLM models
        if (firstChunk) {
            chunk.trimStart()
            firstChunk = false
        }
        fullText += chunk
        props.editor.insertText(chunk)
    }
    function onError() {
        toast.showErrorToast(t('lumo.error'))
        copilotBusy.value = false
    }
    async function onComplete() {
        if (!props.editor) return

        const html = await marked.parse(fullText)
        props.editor.replaceWithHTML(html, lastIndex)
        props.editor.save()
        copilotBusy.value = false
    }
}

function clearEditor() {
    props.editor?.replaceWithHTML('', 0)
}
</script>
<template>
    <div v-if="isEnabled && editor" class="flex">
        <slot v-bind="{ copilotBusy, lumoLabel, validateInputs, hasDescription }">
            <LumoActionMenu
                v-if="copilotBusy || hasDescription"
                :type="hasDescription || copilotBusy ? 'lumo' : 'plain'"
                :loading="copilotBusy"
                :label="lumoLabel"
                data-testid="copilot-create-description-from-text"
                @append="generateDescription({ shouldReplace: false })"
                @replace="generateDescription({ shouldReplace: true })"
            />
            <LumoInlineMessage v-else :message="$t('lumo.description')">
                <LumoButton
                    :disabled="lumoButtonDisabled"
                    :loading="copilotBusy"
                    :label="lumoLabel"
                    data-testid="copilot-create-description-from-text"
                    @click="generateDescription({ shouldReplace: false })"
                />
            </LumoInlineMessage>
        </slot>
    </div>
</template>
