import { computed, ref, type Ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { QuillEditor, Delta, Quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import 'quill-paste-smart'
import Mention from 'quill-mention'
import MagicUrl from 'quill-magic-url'
import DOMPurify from 'dompurify'

import { useMagicKeys } from '@vueuse/core'
import { type QuillDeltaType, isNotWhiteSpace, getTextFromDelta } from '@mindfuel/server/src/common/text-editor'
import { mentionModuleOptions } from './quillMention'
import DTextEditorLinkPanel from '@/components/DTextEditorLinkPanel.vue'

// Text line height in rem
export const LINE_HEIGHT = 1.75
export const EDITOR_PADDING = 1.3
export const COLLAPSED_MAX_ROWS = 5

export interface TextEditorContent {
    delta: QuillDeltaType
}

export interface TextEditorChangeEvent {
    current: QuillDeltaType
    old: QuillDeltaType
    length: number
    isChanged: boolean
}

export type RangeStatic = { index: number; length: number }

export type TextEditorFormat =
    | 'bold'
    | 'italic'
    | 'underline'
    | 'strike'
    | 'link'
    | 'heading'
    | 'ordered-list'
    | 'bullet-list'
    | 'task-list'
    | 'mention'
    | 'clean'

export interface TextEditorProps {
    value?: string | null
    delta?: Delta
    placeholder?: string
    size?: 'sm' | 'md'
    disabledHeadings?: boolean
    minRows?: number
    maxRows?: number
    readonly?: boolean
    required?: boolean
    noPadding?: boolean
    saveOnBlur?: boolean
    autoFocus?: boolean
    defaultStateBorder?: boolean
    hasCancelButton?: boolean
    saveButtonLabel?: string
    saveButtonIcon?: string
    maxCharacters?: number
    excludeFormats?: TextEditorFormat[]
    ghost?: boolean
    collapsed?: boolean
    expandButtonLabel?: string
    collapseButtonLabel?: string
    /**
     * The value of 'data-texteditor-boundary' attribute to identify a DOM Element used
     * to bound text editor tooltip. Defaults to an empty value which references the main
     * column in the Details Layout. Other valus are assigned to various elements in the
     * application.
     */
    boundsValue?: string

    /**
     * Add unique editorId for multiple instances of TextEditor in the same page
     */
    editorId?: string
    tabIndex?: number
}

export type TextEditorEmits = {
    (event: 'text-change', value: TextEditorContent): void
    (event: 'cancel-change'): void
    (event: 'change', value: TextEditorChangeEvent): void
}

/* TODO: these events will be moved back into the DTextEditor component */
interface EventHandlersProps {
    quillInstance: Ref<typeof QuillEditor | undefined>
    props: TextEditorProps
    emit: TextEditorEmits
    deltaValue: Ref<Delta>
    selectionFormat: Ref<string>
    theme?: 'bubble' | ''
    showToolbar?: Ref<boolean>
}

const eventHandlers = ({
    quillInstance,
    deltaValue,
    emit,
    props,
    selectionFormat,
    showToolbar,
}: EventHandlersProps) => {
    let isContentChanged = false // might move

    const blurEditor = () => {
        const mention = quillInstance.value?.getQuill().getModule('mention')
        if (mention?.isOpen || quillInstance.value?.getQuill().hasFocus()) {
            quillInstance.value?.getQuill().blur()
        }
        selectionFormat.value = ''
        if (showToolbar) {
            showToolbar.value = false
        }
    }

    const onSave = () => {
        const currentDelta = quillInstance.value?.getContents()

        if (props.required && !isNotWhiteSpace(currentDelta)) {
            quillInstance.value?.setContents(deltaValue.value)

            blurEditor()
            return
        }

        if (isContentChanged) {
            emit('text-change', { delta: currentDelta })
        }
        isContentChanged = false

        blurEditor()
    }

    const onCancel = () => {
        quillInstance.value?.setContents(deltaValue.value)

        emit('cancel-change')
        isContentChanged = false

        blurEditor()
    }

    const onTextChange = () => {
        const currentDelta = quillInstance.value?.getContents() as Delta

        emit('change', {
            current: currentDelta as QuillDeltaType,
            old: deltaValue.value as QuillDeltaType,
            length: getContentLength(quillInstance),
            isChanged: !!currentDelta.diff(deltaValue.value).length(),
        })

        if (isContentChanged) return

        isContentChanged = JSON.stringify(currentDelta) !== JSON.stringify(deltaValue.value)
    }

    return { blurEditor, onSave, onCancel, onTextChange }
}

export const useLinks = (
    quillInstance: Ref<typeof QuillEditor | undefined>,
    linkPanel: Ref<typeof DTextEditorLinkPanel | undefined>,
    selection: Ref<{ index: number; length: number } | undefined>
) => {
    const editLink = (event: Event) => {
        const quill = quillInstance.value?.getQuill()
        const [link] = quill.scroll.descendant(CustomLinkSanitizer, selection.value?.index)

        if (link?.domNode) {
            selection.value = quill.getSelection()
            linkPanel.value?.show(event, link.domNode)
            return
        }
        linkPanel.value?.show(event)
    }

    const linkSubmit = (value: { text: string }) => {
        if (selection.value) {
            quillInstance.value
                ?.getQuill()
                .formatText(selection.value.index, selection.value.length, 'link', value.text, 'api')
            linkPanel.value?.hide()
        }
    }

    const linkReset = () => {
        if (selection.value) {
            quillInstance.value
                ?.getQuill()
                .formatText(selection.value.index, selection.value.length, 'link', false, 'api')
            linkPanel.value?.hide()
        }
    }

    return { editLink, linkSubmit, linkReset }
}

export const getContentLength = (quillInstance: Ref<typeof QuillEditor | undefined>) => {
    const delta = quillInstance.value?.getContents()
    return getTextFromDelta(delta).replace('\n', '').length
}

const setFormat = (
    quillInstance: Ref<typeof QuillEditor | undefined>,
    format: { type?: string; value?: number | boolean; selection?: RangeStatic }
) => {
    const quill = quillInstance.value?.getQuill()
    if (!format.selection) return
    if (format?.type === 'heading') {
        quill.formatLine(format.selection?.index, format.selection?.length, 'header', format.value)
    }
}

export const useTextEditorMethods = (
    quillInstance: Ref<typeof QuillEditor | undefined>,
    props: TextEditorProps,
    emit: TextEditorEmits,
    selectionFormat: Ref<string>,
    showToolbar?: Ref<boolean>
) => {
    const deltaValue = computed(() => {
        if (props.delta) return new Delta(props.delta)
        try {
            return new Delta(JSON.parse(props.value || '[]'))
        } catch (error) {
            return new Delta({ ops: [{ insert: props.value || '\n' }] })
        }
    })
    const { meta_enter, escape } = useMagicKeys()
    watch([meta_enter, escape], ([meta_enter, escape]) => {
        // Always save on Ctrl + Enter / Command + Enter
        if (meta_enter && quillInstance.value?.getQuill().hasFocus()) onSave()
        // Save on Esc if saveOnBlur is enabled - otherwise cancel out of text editor
        if (escape && quillInstance.value?.getQuill().hasFocus()) {
            if (props.saveOnBlur) {
                onSave()
            } else {
                onCancel()
            }
        }
    })

    const { blurEditor, onTextChange, onCancel, onSave } = eventHandlers({
        quillInstance,
        props,
        emit,
        deltaValue,
        selectionFormat,
        showToolbar,
    })

    const getText = () => {
        return quillInstance.value?.getQuill().getText()
    }

    const getLastIndex = () => {
        const quill = quillInstance.value?.getQuill()
        if (!quill) return
        return quill.getLength() - 1
    }

    const insertLineBreak = () => {
        insertText('\n')
    }

    const insertText = (text: string) => {
        const quill = quillInstance.value?.getQuill()
        if (!quill) return
        const lastIndex = quill.getLength() - 1
        const change = new Delta().retain(lastIndex).insert(text)
        quill.updateContents(change)
    }

    // Removes all text beginning at index and replaces with the HTML
    const replaceWithHTML = (html: string, index: number) => {
        const quill = quillInstance.value?.getQuill()
        if (!quill) return
        quill.updateContents(new Delta().retain(index).delete(quill.getLength() - index))
        const sanitized = DOMPurify.sanitize(html)
        quill.clipboard.dangerouslyPasteHTML(index, sanitized)
    }

    return {
        deltaValue,
        blurEditor,
        onTextChange,
        onCancel,
        onSave,
        getText,
        getLastIndex,
        insertLineBreak,
        insertText,
        replaceWithHTML,
        setFormat: setFormat.bind(null, quillInstance),
        getContentLength: getContentLength.bind(null, quillInstance),
    }
}

interface ModuleOptions {
    editMode?: Ref<boolean>
    linkPanel: Ref<typeof DTextEditorLinkPanel | undefined>
}

const Link = Quill.import('formats/link')
Link.PROTOCOL_WHITELIST = ['http', 'https']

export class CustomLinkSanitizer extends Link {
    static sanitize(url: string) {
        const sanitizedUrl = super.sanitize(url)
        if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl
        const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some((protocol: string) =>
            sanitizedUrl.startsWith(protocol)
        )
        if (hasWhitelistedProtocol) return sanitizedUrl
        return `https://${sanitizedUrl}`
    }
}

export const registerModules = ({ editMode }: ModuleOptions) => {
    /**
     * Sets the default toolbar icons to null in order to utilize a custom component icon
     * as can be seen here: https://github.com/zenoamaro/react-quill/issues/188#issuecomment-558237979
     */
    const icons = Quill.import('ui/icons')
    icons['bold'] = null
    icons['italic'] = null
    icons['link'] = null
    icons['underline'] = null
    icons['strike'] = null
    icons['header'] = null
    icons['list'] = null
    icons['clean'] = null

    /**
     * Custom solution to add protocol to links that don't have one.
     * https://github.com/quilljs/quill/issues/262#issuecomment-948890432
     */
    /* TODO: Might be extracted into the custom link blot */

    Quill.register(CustomLinkSanitizer, true)
    Quill.register('modules/magicUrl', MagicUrl)

    const { t } = useI18n()
    const Clipboard = Quill.import('modules/clipboard')
    const modules = [
        { name: 'mention', module: Mention, options: mentionModuleOptions(editMode ?? ref(true), t) },
        /* This specific modification of the clipboard module requires the 'quilt-paste-smart' plugin to function. */
        {
            name: 'clipboard',
            module: Clipboard,
            options: {
                // Converts the selected text to a link without replacing it. This works only if the link is a valid 'secured' protocol
                magicPasteLinks: true,
            },
        },
        { name: 'magicUrl', module: MagicUrl },
    ]
    return { modules }
}
