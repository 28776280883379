<script lang="ts" setup>
import { useAuthStore } from '@/stores/authStore'
import { useFeatureToggles } from '@/composables/featureToggles'

import DMenuSideNav from '@/components/DMenuSideNav.vue'
import MarkerButton from '@/components/MarkerButton.vue'

const authStore = useAuthStore()
const { list, isPending } = useFeatureToggles()
</script>
<template>
    <div v-if="!isPending" class="flex min-h-screen min-w-screen">
        <DMenuSideNav
            :user-roles="authStore.userRoles"
            :feature-toggles="list"
            class="overflow-y-auto fixed z-sidebar shrink-0"
            route="/settings"
            :title="$t('settings.title')"
        />
        <div class="flex flex-grow flex-col items-center p-4 ml-64">
            <div class="w-full lg:w-[80%]">
                <router-view />
            </div>
            <MarkerButton />
        </div>
    </div>

    <div v-else>
        <!-- Loading Skeleton -->
        <div class="flex h-screen space-x-4 p-4">
            <div class="w-52">
                <PSkeleton height="20rem" class="bg-bluegray-200 mb-2" />
            </div>
            <div class="w-full max-w-screen-xl flex-grow">
                <PSkeleton height="4rem" class="bg-bluegray-200 mb-2" />
                <PSkeleton height="4rem" class="bg-bluegray-200 mb-2 opacity-60" />
                <PSkeleton height="4rem" class="bg-bluegray-200 mb-2 opacity-40" />
            </div>
        </div>
    </div>
</template>
