import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { EntityCoreTypeEnum } from '@prisma/client'
import { client } from '@/plugins/trpc'

export const getEntityRoutePath = async (
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const entityId = to.params.id as string
    const tab = to.params.tab as string
    const tabPath = tab ? `/${tab}` : ''

    try {
        const { routePath, type } = await client.entityType.getByEntityId.query({ id: entityId })
        next({
            path:
                type === EntityCoreTypeEnum.Opportunity
                    ? `/${routePath}/${entityId}${tabPath}`
                    : `/data-product/${routePath}/${entityId}${tabPath}`,
            query: to.query,
        })
    } catch {
        next('/not-found')
    }
}
