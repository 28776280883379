import { client } from '@/plugins/trpc'

import 'quill-mention'
import { type ComposerTranslation } from 'vue-i18n'
import { type Ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { Quill } from '@vueup/vue-quill'
import { type Mention, type MentionType } from '@mindfuel/server/src/common/text-editor'

const MentionBlot = Quill.import('blots/mention')
class CustomMentionBlot extends MentionBlot {
    static render(data: { entityId?: string; value: string; type: string; link?: string; target?: string }) {
        if (data.link) {
            const element = document.createElement('a')
            element.href = data.link
            element.target = data?.target || '_blank'
            element.textContent = data.value
            return element
        } else {
            const element = document.createElement('span')
            element.textContent = data.value
            return element
        }
    }

    // The original 'getClickHandler' function overrides the click event with the e.preventDefault().
    // We remove this to allow the default click event
    getClickHandler() {
        return (e: MouseEvent) => {
            const event = this.buildEvent('mention-clicked', e)
            window.dispatchEvent(event)
        }
    }
}

Quill.register(CustomMentionBlot)

export const isMentionItem = (quill: Quill, cursorPosition: number) => {
    /* The getLeaf method returns the leaf blot at the specified index. This is still experimental https://quilljs.com/docs/api/#getleaf-experimental */
    if (!quill) return
    const [leaf] = quill.getLeaf(cursorPosition + 1)
    const isMention = leaf?.statics?.blotName === 'mention'

    return isMention
}

export const showMentionPopup = (quill: Quill, char: '@' | '#') => {
    const selection = quill.getSelection()
    if (selection?.length) {
        quill.deleteText(selection?.index, selection?.length, Quill.sources.USER)
    }
    quill?.getModule('mention').openMenu(char)
}

export const mentionModuleOptions = (editMode: Ref<boolean>, t: ComposerTranslation) => {
    return {
        allowedChars: /^(?![\W])[\w\d\D\s]*$/, // Don't allow any non word or non digit character at the beginning
        mentionDenotationChars: ['@', '#'],
        allowInlineMentionChar: true,
        isolateCharacter: true,
        positioningStrategy: 'fixed',
        dataAttributes: [
            'id',
            'entityId',
            'value',
            'type',
            'denotationChar',
            'link',
            'target',
            'disabled',
            'entityType',
        ],
        renderItem(item: Mention) {
            const listItem = document.createElement('div')
            const listItemText = document.createElement('span')
            const listItemClasses =
                item.disabled || item.type === 'empty'
                    ? ['ql-mention-list-item-item', 'ql-no-list-item']
                    : ['ql-mention-list-item-item']

            listItem.classList.add(...listItemClasses)

            listItemText.classList.add('ql-list-item-text')
            listItemText.textContent = item.value
            listItem.appendChild(listItemText)

            if (item.type === 'entity' && item.entityType) {
                const entityKeyword = document.createElement('span')
                entityKeyword.textContent = item.entityType

                const keywordClass = [
                    'ml-2',
                    'w-fit',
                    'text-xs',
                    'px-1',
                    'h-4',
                    'rounded-sm',
                    'bg-slate-100',
                    'text-slate-700',
                ]
                entityKeyword.classList.add(...keywordClass)
                listItem.appendChild(entityKeyword)
            }

            return listItem
        },
        source: async function (
            searchTerm: string,
            renderList: (data: Partial<Mention>[], searchTerm: string) => void,
            mentionChar: string
        ) {
            if (!editMode.value) return

            let values: Partial<Mention>[] = []
            if (mentionChar === '@') {
                const result = await client.mention.searchUsers.query({ query: searchTerm })
                values = result.map((item) => ({
                    id: uuidv4(),
                    entityId: item.id,
                    value: item.name || item.email,
                    type: 'person',
                }))
            } else {
                const result = await client.mention.searchFuzzy.query({ query: searchTerm })

                values = result.map((item) => {
                    return {
                        id: uuidv4(),
                        entityId: item.id,
                        value: item.label,
                        type: 'entity' as MentionType,
                        entityType: item.entityType,
                        link: `/entity/${item.id}`,
                        target: '_blank',
                    }
                })
            }
            if (!values.length) {
                const translationKey = mentionChar === '@' ? 'person' : 'item'
                if (!searchTerm) {
                    values = [
                        {
                            id: undefined,
                            value: t(`search.${translationKey}.typeToSearch`),
                            disabled: true,
                            type: 'empty',
                        },
                    ]
                } else {
                    values = [
                        {
                            id: undefined,
                            value: t(`search.${translationKey}.notFound`),
                            disabled: true,
                            type: 'empty',
                        },
                    ]
                }
            }

            renderList(values, searchTerm)
        },
    }
}
