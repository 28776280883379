<script lang="ts" setup>
import { computed } from 'vue'
import { EntityCoreTypeEnum } from '@prisma/client'

import type { SelectorItem } from '@/composables/selector'
import { type PriorityEnumUnion, usePriorities } from '@/composables/data/priority'

import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const props = withDefaults(
    defineProps<{
        modelValue: PriorityEnumUnion
        type?: EntityCoreTypeEnum
        buttonType?: DButtonType
        placeholder?: string
        testid?: string
        filterPlaceholder?: string
    }>(),
    {
        type: EntityCoreTypeEnum.Opportunity,
        testid: 'd-selector-priority',
        buttonType: 'ghost',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value?: string): void
    (name: 'unselect'): void
}>()

const priorityItems = usePriorities(props.type)

const selected = computed(() => priorityItems.filter((item) => item.id === props.modelValue))
const showPlaceholder = computed(() => props.placeholder && props.modelValue === 'not_set')

const onModelUpdate = (item?: SelectorItem[]) => {
    if (!item) return
    emit('update:modelValue', item?.[0].id as PriorityEnumUnion)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="priorityItems"
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelUpdate"
    >
        <DButton :type="buttonType ?? 'ghost'" :data-testid="testid">
            <template v-if="showPlaceholder">
                <BaseIcon icon="priority" class="text-slate-500" size="md" />
                {{ placeholder }}
            </template>
            <template v-else>
                <BaseIcon
                    v-if="selected[0]?.icon"
                    :icon="selected[0]?.icon"
                    :class="selected[0]?.iconColor"
                    size="md"
                />
                {{ $t(`priorities.${modelValue}`) }}
            </template>
        </DButton>
    </DSelectorGeneric>
</template>
