import DefaultLayout from './DefaultLayout.vue'
import DefaultV2Layout from './DefaultV2Layout.vue'
import DefaultListLayout from './DefaultListLayout.vue'
import LoadingLayout from './LoadingLayout.vue'
import LoginLayout from './LoginLayout.vue'
import EmptyLayout from './EmptyLayout.vue'
import DetailsLayout from './DetailsLayout.vue'
import ProfileLayout from './ProfileLayout.vue'
import SettingsLayout from './SettingsLayout.vue'

export interface Layouts {
    Default: typeof DefaultLayout
    DefaultV2: typeof DefaultV2Layout
    DefaultList: typeof DefaultListLayout
    Loading: typeof LoadingLayout
    Login: typeof LoginLayout
    Empty: typeof EmptyLayout
    Details: typeof DetailsLayout
    Profile: typeof ProfileLayout
    Settings: typeof SettingsLayout
}

export const layouts: Layouts = {
    Default: DefaultLayout,
    DefaultV2: DefaultV2Layout,
    DefaultList: DefaultListLayout,
    Loading: LoadingLayout,
    Login: LoginLayout,
    Empty: EmptyLayout,
    Details: DetailsLayout,
    Profile: ProfileLayout,
    Settings: SettingsLayout,
}
