<script lang="ts" setup>
import { computed, useSlots } from 'vue'
import BaseIcon from './BaseIcon.vue'
import type { PassThrough } from 'primevue/ts-helpers'
import type { ButtonPassThroughOptions } from 'primevue/button'
import type { Size } from '@/composables/sizing'

export type DButtonType = 'common' | 'ghost' | 'danger' | 'confirm'

export interface DButtonProps {
    id?: string
    label?: string
    type?: DButtonType
    size?: Size
    disabled?: boolean
    loading?: boolean
    active?: boolean
    submit?: boolean
    fluid?: boolean
    icon?: string
    iconClass?: string
    iconColor?: string
    iconRight?: string
    iconRightClass?: string
    iconStroke?: number
    horizontalAlignment?: 'left' | 'center' | 'right'
}

const props = withDefaults(defineProps<DButtonProps>(), {
    type: 'common',
    size: 'md',
    disabled: false,
    active: false,
    loading: false,
    fluid: false,
    horizontalAlignment: 'center',
})

const slots = useSlots()
const buttonPT = computed<PassThrough<ButtonPassThroughOptions>>(() => ({
    root: {
        class: [
            'flex shrink-0 cursor-pointer max-w-full items-center gap-1 rounded outline-none transition-all duration-200 ease-in-out whitespace-nowrap',
            // Types
            {
                'border border-slate-400': props.type === 'common' && (props.label || slots?.default),
                // lighter border for icon only buttons
                'border border-slate-300': props.type === 'common' && !props.label && !slots?.default,
                'bg-white text-slate-500 hover:bg-slate-100 hover:text-slate-700':
                    !props.disabled && !props.active && props.type === 'common',
                'bg-transparent text-slate-500 hover:bg-slate-100':
                    !props.disabled && !props.active && props.type === 'ghost',
                'bg-rose-700 text-rose-50 hover:bg-rose-500': !props.active && props.type === 'danger',
                'bg-slate-700 text-slate-50 hover:bg-slate-500': !props.active && props.type === 'confirm',
            },
            // Sizes
            {
                'px-1 text-xsmall': props.size === 'sm' && (props.label || slots?.default),
                'px-2 py-[1px] text-small-medium min-h-6': props.size === 'md' && (props.label || slots?.default),
                'px-3 py-[5px] text-small-medium': props.size === 'lg' && (props.label || slots?.default),
            },
            // States
            {
                'bg-rose-500 text-white': props.active && props.type === 'danger',
                'bg-slate-500 text-white': props.active && props.type === 'confirm',
                'opacity-30 pointer-events-none select-none': props.disabled,
                'bg-slate-100 text-slate-500':
                    (props.active || props.disabled) && (props.type === 'ghost' || props.type === 'common'),
                'w-full gap-2': props.fluid,
            },
            // Icon only
            {
                'p-0.5 size-5': !props.label && !slots?.default && props.size === 'sm',
                'p-1 size-6': !props.label && !slots?.default && props.size === 'md',
                'p-1.5 size-8': !props.label && !slots?.default && props.size === 'lg',
            },
            /* Horizontal Alignment */
            {
                'justify-start': props.horizontalAlignment === 'left',
                'justify-center': props.horizontalAlignment === 'center',
                'justify-end': props.horizontalAlignment === 'right',
            },
        ],
    },
}))

const buttonIcon = computed(() => (props.loading ? 'loader' : props.icon))
const buttonIconClass = computed(() => (props.loading ? `animate-spin ${props.iconClass}` : props.iconClass))

const emit = defineEmits<{ (name: 'click', event: Event): void }>()

const onClick = (e: Event) => {
    if (!props.disabled && !props.loading) {
        emit('click', e)
    }
}
</script>

<template>
    <PButton unstyled :pt="buttonPT" :type="submit ? 'submit' : 'button'" :loading="loading" @click="onClick">
        <BaseIcon
            v-if="buttonIcon"
            :icon="buttonIcon"
            :class="buttonIconClass"
            :style="{ color: iconColor }"
            :size="size === 'lg' ? size : 'md'"
            :stroke="iconStroke"
        />
        <slot>
            <span v-if="label">{{ label }}</span>
        </slot>

        <BaseIcon
            v-if="iconRight"
            :icon="iconRight"
            :class="iconRightClass"
            :style="{ color: iconColor }"
            :size="size === 'lg' ? size : 'md'"
            :stroke="iconStroke"
        />
    </PButton>
</template>
